import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { CateringHeader, BannerLeft } from "../utils"
import ContactForm from "../components/globals/ContactForm"
import img from "../images/bcg/events-catering.jpg"

const CateringPage = () => (
  <Layout>
    <Seo
      title="Catering & Events"
      keywords={[`Catering in Asheville and Biltmore`]}
    />
    <CateringHeader img={img}>
      <BannerLeft
        title="Catering & Events"
        subtitle="Make your event delicious"
      >
        <hr />
        <p>
          A great event is a whole lot like a great meal: it’s fresh, it’s
          tasteful, and it happens at just the right time.
        </p>
      </BannerLeft>
    </CateringHeader>

    <section id="catering">
      <div className="row px-0 py-5 justify-content-center">
        <div className="col-12 col-md-10 col-lg-9 px-0 py-4 mx-0">
          <h2>Our Place ... Or Yours.</h2>
          <br />
          <br />
          <p>
            Good food is always a good plan. And good food in an upscale
            atmosphere, with optional outdoor dining— is an even better plan! So
            whether you're hosting a banquet or a ball, a wedding or a
            work-thing, or a get-together with the girls, adding Azalea Bar &
            Kitchen to the guest list is as easy as filling out the form below.
            We can't wait to serve you!
          </p>
          <br />
          <br />
        </div>
      </div>
      <h2>Get a Quote.</h2>
      <div className="row px-0 justify-content-center catering-form">
        <ContactForm></ContactForm>
      </div>
      <br />
      <br />
      <br />
    </section>
  </Layout>
)

export default CateringPage
